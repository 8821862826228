import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { StorageService } from "./storage.service";

@Injectable({
	providedIn: "root",
})
export class BaseService {
	protected apiUrl: string;
	protected audioConverterUrl: string;
	protected http: HttpClient;
	protected router: Router;
	protected storageService: StorageService;

	constructor(protected injector: Injector) {
		this.http = injector.get(HttpClient);
		this.apiUrl = environment.apiUrl;
		this.audioConverterUrl = environment.audioConverterUrl;
		this.router = injector.get(Router);
		this.storageService = injector.get(StorageService);
	}

	get token(): string | null {
		return this.storageService.getToken();
	}

	protected getAuthHeader() {
		return {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
			}).append("Authorization", `Bearer ${this.token}`),
		};
	}

	protected logOut() {
		this.storageService.clear();

		this.router.navigate(["/app", "auth", "login"]);
	}

	protected extractData(result: any) {
		return result ? result.data : null;
	}

	protected handleError(response: any) {
		if (response?.error?.status === 401 || response?.status === 401) {
			this.logOut();
		}

		return throwError(response?.error);
	}
}
