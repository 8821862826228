export const environment = {
	name: "dev",
	production: false,
	apiUrl: "https://api-dev.whatsauto.com.br",
	brokerOfficialWSUrl: "https://brokerdev-ws.whatsauto.com.br",
	chatFlowAppAddress: "https://dev-chatflow.whatsauto.com.br",
	CDN_URL: "https://dev-cdn.whatsauto.com.br/files",
	audioConverterUrl: "https://audioconv.whatsauto.com.br/convert",
	brand: "whatsauto",
	sizeLimitWhenSavingCatalog: 10485760,
};
